import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import theme from '@/styles/theme';

const global = createGlobalStyle`
  ${styledNormalize}

  html {
    min-height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    font-family: ${theme.font.sans};
    font-size: 16px;
    font-weight: normal;
    color: ${theme.colors.black};
    background: ${theme.colors.white};
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

export default global;
