import { graphql } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ArrayParam, useQueryParams } from 'use-query-params';

import Box from '@/atoms/Box';
import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

import FilterButton from './FilterButton';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const Bar = styled(Grid.Container)`
  border-bottom: 1px solid ${theme.colors.gray10};
  pointer-events: all;
`;

const FilterType = styled(Text).attrs({ as: 'button' })`
  cursor: pointer;
  appearance: none;
  text-decoration: ${props => (props.isActive ? 'underline' : 'none')};
  text-decoration-thickness: 1px;
  text-underline-offset: 0.125em;
  border: none;
  background: none;
`;

const ScrollWrapper = styled(Box)`
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  margin-top: -20px;
  padding-bottom: 20px;
  transform: translateY(20px);
  width: 100%;
  display: flex;
  align-items: center;
`;

const ScrollContent = styled(Grid)`
  flex-wrap: nowrap;
  min-width: 100%;
`;

const Slider = styled(Box)`
  overflow: hidden;
`;

const FilterButtonHolder = styled(Grid.Item)`
  > *:first-child {
    margin-left: -0.75rem;
  }

  > *:last-child {
    margin-right: 0;
  }

  padding-right: ${theme.grid.outerMargin[0]}rem;

  ${media.sm`
    padding-right: ${theme.grid.outerMargin[1]}rem;
  `}

  ${media.md`
    padding-right: ${theme.grid.outerMargin[2]}rem;
  `}

  ${media.lg`
    padding-right: ${theme.grid.outerMargin[3]}rem;
  `}

  ${media.xl`
    padding-right: ${theme.grid.outerMargin[4]}rem;
  `}
`;

const FilterCountBadge = styled(Text)`
  flex: 0 0 auto;
  background-color: ${theme.colors.green};
  border-radius: 50%;
  color: ${theme.colors.white};
  font-size: 9px;
  height: 10px;
  width: 10px;
  line-height: ${({ showDots }) => (showDots ? '6px' : '10px')};
  text-align: center;
  text-indent: 0.5px;
  margin-left: 2px;
  top: ${({ showDots }) => (showDots ? '-4px' : '-2px')};
  position: relative;
`;

const SecondaryBar = ({
  navigationItems,
  location,
  factoryLocation,
  latestBatchTitle,
  nextBatchDate,
  availableFilters,
  ...props
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [filters, setFilters] = useQueryParams({
    style: ArrayParam,
    color: ArrayParam,
    size: ArrayParam,
  });

  const preSelectedFilter =
    Object.entries(filters).find(([, value]) => value) ?? [];

  const [filterType, setFilterType] = useState(preSelectedFilter[0] ?? 'style');

  const filterPressed = (type, value) => {
    if (filters[type]?.includes(value)) {
      setFilters(
        {
          [type]: filters[type].filter(v => v !== value),
        },
        'pushIn'
      );
    } else {
      setFilters(
        {
          [type]: [...(filters[type] ?? []), value],
        },
        'pushIn'
      );
    }
  };

  const onScroll = e => {
    setScrolled(e.target.scrollLeft !== 0);
  };

  const renderFilterCountBadge = type => {
    const count = filters[type]?.length;
    if (!count) {
      return <></>;
    }
    if (count > 7) {
      return (
        <FilterCountBadge $bold showDots>
          ...
        </FilterCountBadge>
      );
    }

    return <FilterCountBadge $bold>{count}</FilterCountBadge>;
  };

  return (
    <Bar
      bg="white"
      $height={theme.header.secondaryBarHeight}
      $position="absolute"
      left={0}
      right={0}
      top={theme.header.topBarHeight}
      {...props}
    >
      <Grid
        $height="100%"
        alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
      >
        <Grid.Item
          $display="flex"
          alignItems={['baseline', 'baseline', 'baseline', 'flex-start']}
          flexWrap="wrap"
          justifyContent="space-between"
          mt={[0.5, 0.5, 0.5, 0]}
          w={[1, 1, 1, 5.5 / 24, 4 / 24]}
        >
          <Box
            $display="flex"
            alignItems="baseline"
            flexBasis={['auto', 'auto', 'auto', '100%']}
            flexGrow={1}
            flexShrink={1}
          >
            <Text t={-2} mr={0.5} flex="0 0 auto">
              Filter by
            </Text>
            <FilterType
              onClick={() => setFilterType('style')}
              isActive={filterType === 'style'}
              $bold
            >
              Style
            </FilterType>
            {renderFilterCountBadge('style')}
            <FilterType
              onClick={() => setFilterType('color')}
              isActive={filterType === 'color'}
              ml={0.5}
              $bold
            >
              Color
            </FilterType>
            {renderFilterCountBadge('color')}
            <FilterType
              onClick={() => setFilterType('size')}
              isActive={filterType === 'size'}
              ml={0.5}
              $bold
            >
              Size
            </FilterType>
            {renderFilterCountBadge('size')}
          </Box>
        </Grid.Item>

        <Grid.Item w={[1, 1, 1, 18.5 / 24, 20 / 24]} alignSelf="stretch">
          <Slider
            borderLeft={[
              '0px',
              '0px',
              '0px',
              scrolled ? `1px solid ${theme.colors.gray10}` : '0px',
            ]}
            $height="100%"
            $display="flex"
            ml={[
              -theme.grid.outerMargin[0],
              -theme.grid.outerMargin[1],
              -theme.grid.outerMargin[2],
              0,
            ]}
            mr={theme.grid.outerMargin.map(m => -m)}
          >
            <ScrollWrapper
              onScroll={onScroll}
              px={[
                theme.grid.outerMargin[0],
                theme.grid.outerMargin[1],
                theme.grid.outerMargin[2],
                0,
              ]}
            >
              <ScrollContent>
                <Grid.Item
                  key="insetLeft"
                  $display={['none', 'none', 'none', 'block']}
                  w={[
                    0,
                    0,
                    0,
                    `calc((100vw - ${theme.grid.outerMargin[3]}rem) / 24)`,
                    `calc((100vw - ${theme.grid.outerMargin[4]}rem) / 12)`,
                  ]}
                />
                <FilterButtonHolder $display="flex">
                  {availableFilters[`${filterType}Filters`].map(
                    availableFilter => (
                      <FilterButton
                        filterType={filterType}
                        key={availableFilter._id ?? availableFilter._key}
                        mr={0.5}
                        isSelected={filters[filterType]?.includes(
                          availableFilter.value ?? availableFilter.slug.current
                        )}
                        category={availableFilter.category}
                        icon={availableFilter.icon}
                        iconStyle={availableFilter.iconStyle}
                        iconBorder={availableFilter.iconBorder}
                        onClick={() =>
                          filterPressed(
                            filterType,
                            availableFilter.value ??
                              availableFilter.slug.current
                          )
                        }
                      >
                        {availableFilter.title}
                      </FilterButton>
                    )
                  )}
                </FilterButtonHolder>
              </ScrollContent>
            </ScrollWrapper>
          </Slider>
        </Grid.Item>
      </Grid>
    </Bar>
  );
};

export default SecondaryBar;

export const query = graphql`
  fragment filterData on SanityShopSettingsFilters {
    styleFilters {
      _id
      title
      slug {
        current
      }
      ...indexItemIconData
    }
    sizeFilters {
      _key
      value
      title
    }
    colorFilters {
      _id
      ...indexItemIconData
      title
      slug {
        current
      }
    }
  }
`;
