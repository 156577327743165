import React, { useState, useEffect } from 'react';

import Box from '@/atoms/Box';
import Link from '@/atoms/Link';
import NavigationItem from '@/atoms/NavigationItem';

import { isPathActive } from '@/utils/routing';
import Collapsible from '@/components/Collapsible';

const Navigation = ({ navigationItems, drawerItems, drawerOpen, location, closeNav, toggleDrawer, activeStyle, isSmallScreen, ...props }) => {

  const [activePath, setActivePath] = useState(null);

  // we cache the active path so it does not get updated when a page is openend in a modal
  useEffect(() => {
    if (location?.state && !location.state.modal) {
      setActivePath(location.pathname);
    }
  }, [location?.pathname, location?.state]);


  const handleParentClicked = (i) => {
    console.log('isSmallScreen', isSmallScreen);
    if (isSmallScreen) {
      console.log('toggleTiny');
    } else {
      toggleDrawer(i)
    }
  }

  return (
    <Box as="nav" role="navigation" {...props}>
      <Box
        as="ul"
        $listStyle="none"
        $display={['block', 'block', 'block', 'flex']}
        mb={[-0.75, -0.75, -0.75, 0]}
      >
        {navigationItems?.map(({ key, target, children }, i) => (
          <NavigationItem
            key={key}
            as="li"
            t="navItem"
            mr={[0, 0, 0, 1, 2]}
            mb={[0.75, 0.75, 0.75, 0]}
            activeStyle={activeStyle}
            isActive={activeStyle && activeStyle === 'drawer' ? (
              drawerOpen === i
            ) : (
              target.link && activePath && isPathActive(target.link.path, activePath)
            )}
          >
            {children && children.length ? (
              <>
                <Link onClick={() => handleParentClicked(i)}>{target.title}</Link>
                {isSmallScreen &&
                  <Collapsible
                    open={true}
                    pt={[0.5]}
                    pb={[1.5]}
                  >
                    {children?.map((child) => (
                      <Box
                        key={child.key}
                        as="ul"
                        $listStyle="none"
                      >
                        <NavigationItem
                          as="li"
                          t="navItemSmall"
                          mr={[0, 0, 0, 1, 2]}
                          mb={[0.5, 0.5, 0.5, 0]}
                        >
                          <Link
                            link={child.target.link}
                            url={child.target.url}
                            targetBlank={child.target.targetBlank}
                            onClick={() => closeNav()}
                          >
                            {child.target.title}
                          </Link>
                        </NavigationItem>
                      </Box>
                    ))}
                  </Collapsible>
                }
              </>
            ) : (
              <Link
                link={target.link}
                url={target.url}
                targetBlank={target.targetBlank}
                onClick={() => closeNav()}
              >
              {target.title}
              </Link>
            )}
          </NavigationItem>
        ))}
      </Box>
    </Box>
  );
};

export default Navigation;
