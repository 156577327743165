import React from 'react';
import styled from 'styled-components';

import Flyout from '@/atoms/Flyout';
import Grid from '@/atoms/Grid';
import Link from '@/atoms/Link';
import Logo from '@/atoms/Logo';
import CartButton from '@/components/CartButton';


import FlyoutToggle from './FlyoutToggle';
import Main from './Main';

import { useRegionContext } from '@/context/regionContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useLockBodyScroll from '@/hooks/useLockBodyScroll';
import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const LogoLink = styled(Link)`
  display: inline-block;
  font-size: 0;
  color: ${theme.colors.black};
`;

const Bar = styled(Grid.Container)`
  pointer-events: all;

  ${media.lg`
    border-bottom: 1px solid ${props =>
      props.withBorder ? theme.colors.gray10 : 'transparent'};
    transition: border-color 0.2s ease;
  `}

  .headroom--scrolled && {
    ${media.lg`
      border-bottom-color: ${theme.colors.gray10};
    `}
  }
`;

const TopBar = ({
  navigationItems,
  drawerItems,
  location,
  factoryLocation,
  latestBatchTitle,
  latestBatchLink,
  nextBatchDate,
  toggleDrawer,
  withBorder,
  toggleNav,
  closeNav,
  isNavExpanded,
  ...props
}) => {
  const { region } = useRegionContext();
  const isLargeScreen = useBreakpoint('lg');

  useLockBodyScroll(isLargeScreen === false && isNavExpanded);

  const mainProps = {
    navigationItems,
    drawerItems,
    location,
    factoryLocation,
    latestBatchTitle,
    latestBatchLink,
    nextBatchDate,
  };

  return (
    <>
      <Bar
        bg="white"
        $height={theme.header.topBarHeight}
        withBorder={withBorder}
        style={{ opacity: isLargeScreen === undefined ? 0 : 1 }}
        {...props}
      >
        <Grid
          $height="100%"
          $display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid.Item w={[1 / 2, 1 / 2, 1 / 2, 6.5 / 24, 6 / 24]}>
            <LogoLink to="/" onClick={closeNav}>
              <Logo w={['135px', '135px', '135px', '150px']} $height="auto" />
            </LogoLink>
          </Grid.Item>

          {isLargeScreen === true && (
            <Grid.Item w={[1, 1, 1, 13.5 / 24, 14 / 24]} pt={0.5}>
              <Main {...mainProps} toggleDrawer={toggleDrawer} />
            </Grid.Item>
          )}

          <Grid.Item
            w={[1 / 2, 1 / 2, 1 / 2, 4 / 24]}
            mt="-1px"
            $display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {region === 'EU' && <CartButton onClick={closeNav} />}

            {isLargeScreen === false && (
              <FlyoutToggle
                ml={0.625}
                icon={isNavExpanded ? 'navClose' : 'navHamburger'}
                onClick={toggleNav}
              />
            )}
          </Grid.Item>
        </Grid>
      </Bar>

      {isLargeScreen === false && (
        <Flyout
          open={isNavExpanded}
          $position="absolute"
          top={0}
          left={0}
          right={0}
          bg="white"
          withBorder={withBorder}
          autoAfterExpanded={true}
        >
          <Grid.Container pt={2.5} pb={[1.5, 1.5, 2]}>
            <Main closeNav={closeNav} toggleDrawer={toggleDrawer} isSmallScreen={true} {...mainProps} />
          </Grid.Container>
        </Flyout>
      )}

    </>
  );
};

export default TopBar;
