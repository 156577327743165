import React, { useState } from 'react';
import styled from 'styled-components';

import NavigationItem from '@/atoms/NavigationItem';
import Link from '@/atoms/Link';
import Box from '@/atoms/Box';
import Collapsible from '@/components/Collapsible';

const ToggleButton = styled.button`
appearance: none;
border: none;
border-radius: 0;
background-color: none;
color: currentColor;
`;

const CollapsibleLinkList = ({target, links, closeDrawer, closeNav}) => {

  const [toggled, setToggled] = useState(false);

  const handleParentClicked = () => {
    setToggled(!toggled);
  }

  const title = target?.title || 'No title';

  return (
    <>
      {links && links.length ? (
        <>
          <ToggleButton onClick={() => handleParentClicked()}>{title}</ToggleButton>
          <Collapsible open={toggled} pl={1} autoAfterExpanded={true}>
            <Box
              as="ul"
              pt={.5}
              pb={1}
            >
              {links.map(({ key, target, links, children }) => (
                <NavigationItem
                  key={key}
                  as="li"
                  t="navItemSmall"
                  mr={[0, 0, 0, 1, 2]}
                  mb={[0.75, 0.75, 0.75, 0.15]}
                >
                  {children && children.length ? (
                    <CollapsibleLinkList target={target} links={children} closeDrawer={closeDrawer} closeNav={closeNav} />
                  ): (
                    <>
                      {links && links.length ? (
                        <CollapsibleLinkList target={target} links={links} closeDrawer={closeDrawer} closeNav={closeNav} />
                      ): (
                        <Link
                          link={target.link}
                          url={target.url}
                          targetBlank={target.targetBlank}
                          onClick={closeNav && closeDrawer}
                        >
                          {target.title}
                        </Link>
                      )}
                    </>
                  )}
                </NavigationItem>
              ))}
            </Box>
          </Collapsible>
        </>
      ) : (
        <Link
          link={target.link}
          url={target.url}
          targetBlank={target.targetBlank}
          onClick={closeNav && closeDrawer}
        >
          {title}
        </Link>
      )}
    </>
  );
};

const NestedNavigation = ({items, closeDrawer, closeNav}) => {
  return (
    <Box
    as="ul"
    $listStyle="none"
    $display={['block', 'block', 'block', 'block']}
    mb={[-0.75, -0.75, -0.75, 0]}
    >

      {items?.map(({ key, target, links, children }) => (
        <NavigationItem
          key={key}
          as="li"
          t="navItemLarge"
          mr={[0, 0, 0, 1, 2]}
          mb={[0.75, 0.75, 0.75, 0.15]}
        >

          {children && children.length ? (
            <CollapsibleLinkList target={target} links={children} closeDrawer={closeDrawer} closeNav={closeNav} />
          ) : (
            <>
              {links && links.length ? (
                <CollapsibleLinkList target={target} links={links} closeDrawer={closeDrawer} closeNav={closeNav} />
              ) : (
                <Link
                  link={target.link}
                  url={target.url}
                  targetBlank={target.targetBlank}
                  onClick={closeNav && closeDrawer}
                >
                {target.title}
                </Link>
              )}
            </>
          )}

        </NavigationItem>
      ))}
    </Box>
  );
};

export default NestedNavigation;
